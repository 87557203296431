import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
      Feature Flags and A/B Testing in <span>5 minutes</span>!
      </Heading>
      <Heading variant='h3' sx={styles.running}>
      Deploy incomplete & buggy features because they're off. No more holding up the release until every feature is bug free. Once they're ready and tested, turn them on.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='https://toggly.io'>
        Start Experimenting with Toggly
      </Button>
    </Box>
  </>
)
